@import "../../assets/scss/variables.scss";

.login-wrapp {
  display: flex;
  column-gap: unquote("min(100px, 3vw)");
  .login-decor {
    min-height: 810px;
    padding-top: 90px;
    overflow: hidden;
    flex: 1;
    & > div {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='921' height='903' viewBox='0 0 921 903' fill='none'%3E%3Cpath d='M81.6681 44.0484C84.6811 18.9162 106 9.3575e-05 131.313 9.06829e-05L870.238 6.25688e-06C899.643 2.89716e-06 922.699 25.2523 920.031 54.5364L846.881 857.462C844.237 886.491 817.423 907.112 788.688 902.215L41.9097 774.961C15.6352 770.483 -2.5083 746.183 0.66434 719.719L81.6681 44.0484Z' fill='%23FFF8E8'/%3E%3C/svg%3E");
      background-size: cover;
      background-position: left top;
      background-repeat: no-repeat;
      height: 130%;
      width: 130%;
    }

    &:first-child {
      padding-top: 20px;
      & > div {
        margin-left: -30%;
        background-position: right top;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='875' height='918' viewBox='0 0 875 918' fill='none'%3E%3Cpath d='M790.476 119.675C787.471 96.8258 769.239 78.9903 746.33 76.4889L55.427 1.05187C25.8403 -2.17859 -2.48433e-05 20.9939 -2.48433e-05 50.7565V867.925C-2.48433e-05 898.153 26.6139 921.468 56.5786 917.491L831.494 814.639C858.845 811.008 878.086 785.91 874.489 758.554L790.476 119.675Z' fill='%23FFF8E8'/%3E%3C/svg%3E");
      }
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  @media (max-width: 991px) {
    display: block;
    padding: 40px 50px 50px;
  }
}

.form {
  margin-top: 145px;
  position: relative;
  flex: 0 0 505px;
  @media (min-width: 2000px) {
    margin-top: 5vw;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
    max-width: 505px;
  }
  .header {
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    color: $maastright-blue;
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 15px;
    }
  }
  .header-subtitle {
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    font-family: 'Sofia Pro', sans-serif;
    @media (max-width: 991px) {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 0;
    }
  }

  .form-row {
    margin-bottom: 30px;
    width: 100%;
    @media (max-width: 991px) {
      margin-bottom: 25px;
    }
  }
  .button-row {
    width: 100%;
    button {
      width: calc(100% - 8px);
    }
  }
}

.register-error {
  display: flex;
  gap: 15px;

  button {
    min-width: 280px;
  }
}