@import "../../assets/scss/variables.scss";

.session-modal {
    width: 495px;
}
.session-timeout {
    display: flex;
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    align-items: center;
}
.session-title {
    font-size: 30px;
    font-weight: 700;
    color: #021C3C
}
.login-btn {
    height: 36px;
    min-width: 208px;
    background: $effervescent-blue;
    position: relative;
    cursor: pointer;
    border: none;
    outline: none;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 17px;
    color: $white;
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    justify-content: center;
}
.description {
    align-self: self-start;
    margin-top: 20px;
    font-size: 15px;
}